<template>
  <v-content class="custom-background">
    <router-view />
    <v-spacer />
    <dashboard-core-footer />
  </v-content>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    DashboardCoreFooter: () => import("./Footer")
  }
};
</script>
<style scoped>
.custom-background {
  background-color: #f5f8fa; /* Change this to your desired color */
}
</style>